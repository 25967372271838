/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

import prettyMsFormatter from 'pretty-ms';
import { round } from 'lodash';
import NumberFormatter from '../NumberFormatter';

export default function createDurationFormatter(
  config: {
    description?: string;
    id?: string;
    label?: string;
    multiplier?: number;
  } & (
    | prettyMsFormatter.Options
    | { hoursOnly?: boolean; hoursDigits?: number; hoursDigitsCutoff?: number }
  ) = {},
) {
  const {
    description,
    id,
    label,
    multiplier = 1,
    // @ts-ignore
    hoursOnly = false,
    // @ts-ignore
    hoursDigits = 1,
    // @ts-ignore
    hoursDigitsCutoff = 20,
    ...prettyMsOptions
  } = config;

  const formatFunc = hoursOnly
    ? (value: number) => {
        const digits =
          hoursDigitsCutoff > 0 &&
          Math.abs(value * multiplier) < hoursDigitsCutoff
            ? hoursDigits
            : 0;
        return `${round(value * multiplier, digits ?? 0)}h`;
      }
    : (value: number) => prettyMsFormatter(value * multiplier, prettyMsOptions);

  return new NumberFormatter({
    description,
    formatFunc,
    id: id ?? 'duration_format',
    label: label ?? `Duration formatter`,
  });
}
