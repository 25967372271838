/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import emotionStyled from '@emotion/styled';
import { useTheme as useThemeBasic } from '@emotion/react';
import createCache from '@emotion/cache';

export {
  css,
  keyframes,
  jsx,
  ThemeProvider,
  CacheProvider as EmotionCacheProvider,
  withTheme,
} from '@emotion/react';
export { default as createEmotionCache } from '@emotion/cache';

declare module '@emotion/react' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface Theme extends SupersetTheme {}
}

export function useTheme() {
  const theme = useThemeBasic();
  // in the case there is no theme, useTheme returns an empty object
  if (Object.keys(theme).length === 0 && theme.constructor === Object) {
    throw new Error(
      'useTheme() could not find a ThemeContext. The <ThemeProvider/> component is likely missing from the app.',
    );
  }
  return theme;
}

export const emotionCache = createCache({
  key: 'superset',
});

export const styled = emotionStyled;

// LK-21509 - tried to make these match the admin console where possible
const defaultTheme = {
  borderRadius: 4,
  colors: {
    text: {
      label: '#879399',
      help: '#737373',
    },
    primary: {
      base: '#2587f7',
      dark1: '#1A4B82',
      dark2: '#154882',
      light1: '#7AB8FF',
      light2: '#A6CFFF',
      light3: '#D1E7FF',
      light4: '#E8F3FF',
      light5: '#F5FAFF',
    },
    secondary: {
      base: '#444E7C',
      dark1: '#363E63',
      dark2: '#282E4A',
      dark3: '#1B1F31',
      light1: '#8E94B0',
      light2: '#B4B8CA',
      light3: '#D9DBE4',
      light4: '#ECEEF2',
      light5: '#F5F5F8',
    },
    grayscale: {
      base: '#6C6C6C',
      dark1: '#5E5E5E',
      dark2: '#333333',
      light1: '#B6BBBF',
      light2: '#C2C7CC',
      light3: '#EFF1F3',
      light4: '#FAFAFA',
      light5: '#FFFFFF',
    },
    error: {
      base: '#E54637',
      dark1: '#B8392C',
      dark2: '#631E18',
      light1: '#E54637',
      light2: '#F84C3B',
    },
    warning: {
      base: '#FFBC2B',
      dark1: '#BF912C',
      dark2: '#7D590B',
      light1: '#FFCA57',
      light2: '#FFF0CF',
    },
    alert: {
      base: '#E54637',
      dark1: '#B8392C',
      dark2: '#631E18',
      light1: '#E54637',
      light2: '#F84C3B',
    },
    success: {
      base: '#83B324',
      dark1: '#435C12',
      dark2: '#34480E',
      light1: '#B7C59A',
      light2: '#DAE8BD',
    },
    info: {
      base: '#D5E6FA',
      dark1: '#6FAFF7',
      dark2: '#2587F7',
      light1: '#D5E6FA',
      light2: '#EAF3FE',
    },
  },
  opacity: {
    light: '10%',
    mediumLight: '35%',
    mediumHeavy: '60%',
    heavy: '80%',
  },
  typography: {
    families: {
      sansSerif: `'Nunito Sans', Helvetica, Arial`,
      serif: `Georgia, 'Times New Roman', Times, serif`,
      monospace: `'Fira Code', 'Courier New', monospace`,
    },
    weights: {
      light: 200,
      normal: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
      extraBold: 800,
      ultraBold: 900,
    },
    sizes: {
      xxs: 9,
      xs: 10,
      s: 12,
      m: 14,
      l: 16,
      xl: 21,
      xxl: 28,
    },
  },
  zIndex: {
    aboveDashboardCharts: 10,
    dropdown: 11,
    max: 3000,
  },
  transitionTiming: 0.3,
  gridUnit: 4,
  brandIconMaxWidth: 37,
};

export type SupersetTheme = typeof defaultTheme;

export interface SupersetThemeProps {
  theme: SupersetTheme;
}

export const supersetTheme = defaultTheme;
